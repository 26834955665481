import React, { useState } from 'react';
import {
  Image,
  Form,
  InputGroup,
  Button
} from "react-bootstrap";

const MessageField = ({ innerRef, onSend, loading }) => {

  const [message, setMessage] = useState("");

  const onSubmit = e => {
    e.preventDefault();
    if (message) {
      onSend(message);
      setMessage("");
    }
  }

  return (
    <>
      <div className="chat-ui-main-wrapper-footer">
        <div className="chat-ui-footer-input">
          <Form onSubmit={onSubmit}>
            <InputGroup className="mb-0">
              <Form.Control
                placeholder="Type your message here..."
                value={message}
                onChange={e => setMessage(e.target.value)}
                disabled={loading}
                ref={innerRef}
                autoFocus={true}
              />
              <InputGroup.Text id="basic-addon2">
                <Button type="submit" className="chat-submit-btn" disabled={loading}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="28" width="28"
                    fill="#ff43c0"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21.916 8.727L3.965.282A2.745 2.745 0 00.917.713a2.745 2.745 0 00-.745 2.995c.017.043 4.411 8.296 4.411 8.296S.27 20.255.255 20.297a2.744 2.744 0 00.749 2.993 2.756 2.756 0 001.841.708c.409 0 .819-.092 1.201-.279l17.872-8.438A3.586 3.586 0 0024 12.003c0-1.42-.801-2.675-2.084-3.275zM2.032 2.967a.745.745 0 01.223-.768.744.744 0 01.838-.116l17.974 8.455c.239.112.438.27.591.462H6.315L2.032 2.967zm19.034 10.504L3.178 21.917a.738.738 0 01-.838-.116.74.74 0 01-.223-.769L6.319 13h15.345a1.645 1.645 0 01-.597.471z"></path>
                  </svg>
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </Form>
        </div>
      </div>
    </>
  );
}

export default MessageField;