import React, { useEffect, useRef, useState } from "react";
import { Configuration, OpenAIApi } from "openai";
// import { Link} from "react-router-dom";
import "./Landing.css";
import { Image, Form, InputGroup, Button } from "react-bootstrap";
import MessageField from "../AI/MessageField";

const LandingPageIndex = () => {
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_AI_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const msgFieldRef = useRef(null);
  const latestMsgRef = useRef(null);

  const [chat, setChat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState({
    model: "text-davinci-003",
    prompt: "Human: ",
    temperature: 0.9,
    max_tokens: 250,
    top_p: 1,
    frequency_penalty: 0.0,
    presence_penalty: 0.6,
    stop: [" Human:", " AI:"],
  });

  const onSubmit = (message) => {
    let prompt = "";
    setLoading(true);
    chat.map((chatItem, i) => {
      prompt +=
        (chatItem.type === 1 ? "Human: " : "Robot: ") + chatItem.message + "\n";
    });
    prompt += "Human: " + message;
    setChat([...chat, { type: 1, message: message }]);
    handleAPI(prompt, { type: 1, message: message });
  };

  const handleAPI = async (prompt, chatItem) => {
    try {
      const response = await openai.createCompletion({
        ...option,
        prompt: prompt,
      });
      let robotMsg = response.data.choices[0].text;
      // robotMsg = "Robot: " + robotMsg.replaceAll("Robot: ", "");
      robotMsg = robotMsg.slice(robotMsg.indexOf(":") + 1).trim();
      let chatMessages = [...chat, chatItem];

      // Word Writting
      // robotMsg.split(" ").map((word, i) => {
      //   setTimeout(() => {
      //     let lastChat = { message: "" };
      //     if (i != 0) {
      //       lastChat = chatMessages[chatMessages.length - 1];
      //       chatMessages.pop();
      //     }
      //     chatMessages = [
      //       ...chatMessages,
      //       { type: 2, message: lastChat.message + " " + word },
      //     ];
      //     setChat(chatMessages);
      //   }, 200 * i);
      // });

      // Letter writing
      [...Array(robotMsg.length)].map((val, i) => {
        setTimeout(() => {
          let lastChat = { message: "" };
          if (i != 0) {
            lastChat = chatMessages[chatMessages.length - 1];
            chatMessages.pop();
          }
          chatMessages = [
            ...chatMessages,
            { type: 2, message: robotMsg.substring(0, i + 1) },
          ];
          setChat(chatMessages);
        }, 50 * i);
      });

      setLoading(false);
      setTimeout(() => {
        msgFieldRef.current.focus();
      }, 100);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (latestMsgRef.current)
      latestMsgRef.current.scrollIntoView({
        behavior: "smooth",
      });
  }, [chat]);

  return (
    <>
      <div className="chat-ui-sec">
        <div className="chat-ui-sidebar">
          <a href="#" className="sidebar-logo">
            <Image
              className="logo"
              src={window.location.origin + "/img/logo.png"}
            />
          </a>
        </div>
        <div className="chat-ui-main-wrapper">
          <div className="chat-ui-layout">
            <div className="chat-ui-main-wrapper-header">
              <div className="title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="28"
                  width="28"
                  fill="#ff43c0"
                  viewBox="0 0 24 24"
                >
                  <g data-name="Layer 2">
                    <path d="M12 22.3A10.62 10.62 0 016.84 21a.26.26 0 00-.2 0l-3.53 1a1.24 1.24 0 01-1.56-1.55l1.06-3.55a.27.27 0 000-.19 10.75 10.75 0 1111.69 5.35 11.47 11.47 0 01-2.3.24zm-5.29-2.87a1.85 1.85 0 01.85.22 9.25 9.25 0 102.9-17.23A9.23 9.23 0 003.9 16a1.77 1.77 0 01.15 1.35l-.93 3.09 3.09-.93a1.73 1.73 0 01.5-.08z"></path>
                    <circle cx="8" cy="12" r="1.25"></circle>
                    <circle cx="12" cy="12" r="1.25"></circle>
                    <circle cx="16" cy="12" r="1.25"></circle>
                  </g>
                </svg>
                <h6>AI help-desk agent to grow your sales</h6>
              </div>
              <div>
                {chat.length > 0 ?
                  <button disabled={loading} onClick={() => setChat([])} className="btn-theme-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="28"
                      width="28"
                      fill="#ff43c0"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M21 2H7.76L0 12l7.76 10H21a3 3 0 003-3V5a3 3 0 00-3-3zm1 17a1 1 0 01-1 1H8.74l-6.2-8 6.2-8H21a1 1 0 011 1zm-4.043-9.543L15.414 12l2.543 2.543-1.414 1.414L14 13.414l-2.543 2.543-1.414-1.414L12.586 12l-2.543-2.543 1.414-1.414L14 10.586l2.543-2.543z"></path>
                    </svg>
                  </button>
                  : null
                }
              </div>
            </div>
            <div className="chat-ui-main-wrapper-body">
              {chat.length > 0 ? (
                <>
                  {chat.map((chatItem, i) => (
                    <div
                      className={`chat-ui-main-wrapper-body-header list1
                    ${chatItem.type === 1 ? "user-message" : "bot-message"}
                    `}
                      key={i}
                    >
                      <div className="haive-profile-img">
                        <Image
                          className="chat-room-logo"
                          src={
                            chatItem.type === 1
                              ? window.location.origin + "/img/user-logo.svg"
                              : window.location.origin + "/img/company-logo.png"
                          }
                        />
                      </div>
                      <div className="message-sec">
                        {/* <h6>{chatItem.message}</h6> */}
                        <h6
                          dangerouslySetInnerHTML={{
                            __html: chatItem.message,
                          }}
                        ></h6>
                      </div>
                    </div>
                  ))}
                  {loading ? (
                    <div className="chat-ui-main-wrapper-body-header list2 bot-message">
                      <div className="haive-profile-img">
                        <Image
                          className="chat-room-logo"
                          src={window.location.origin + "/img/company-logo.png"}
                        />
                      </div>
                      <h6>....</h6>
                    </div>
                  ) : null}
                  <div ref={latestMsgRef}></div>
                </>
              ) : (
                <div className="step-1">
                  <div className="chat-intial-content">
                    <h5>Welcome</h5>
                    <p>Please type something to Start messaging.</p>
                    <button
                      className="btn-theme-outline"
                      onClick={() => onSubmit("Hi")}
                    >
                      Say Hi
                    </button>
                  </div>
                </div>
              )}
            </div>
            <MessageField
              innerRef={msgFieldRef}
              onSend={onSubmit}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPageIndex;
